import React from "react"
import { connect } from "react-redux"
import { Container, Col, Row } from "reactstrap"
import { FormattedMessage, injectIntl, navigate, Link } from "gatsby-plugin-intl"
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point'
import Collection from "ol/Collection"

import { countryMarkerStyle } from '../../utils'
import MapService, { createSaticEmptyNoControlsMap } from "../../utils/mapService"

import "../../assets/css/custom.css"
import CloseLogo from "../../assets/img/close.png"


const ParticipatingCountriesSection = ({ intl, classes, regions, agreementId, agreementCountries }) => {

    var featureCollection = new Collection()


    React.useEffect(() => {
        const Style = require('ol/style/Style').default;
        const Fill = require('ol/style/Fill').default;
        const Stroke = require('ol/style/Stroke').default;
        const VectorLayer = require('ol/layer/Vector').default;

        const map = createSaticEmptyNoControlsMap();
        let mapService = new MapService(map)

        var highlightStyle = new Style({
            fill: new Fill({
                color: '#A59A87'
            }),
        });

        var countriesStyle = new Style({
            fill: new Fill({
                color: '#ffffff00'
            }),
            stroke: new Stroke({
                color: '#393e46',
                //color: '#F2F0E8',
                width: 2
            }),
        });

        var oceanStyle = new Style({
            fill: new Fill({
                color: '#AADAFF'
            })
        });

        var countryVector = new VectorLayer({
            source: new VectorSource({
                url: '/countries.geojson',
                format: new GeoJSON()
            }),
            style: function (feature) {
                return countriesStyle;
            }
        });

        const addStyleToFeature = features => {
            const styledFeatures = features.map(f => { f.setStyle(countryMarkerStyle(f.get('name_en'), f.get('name_ar'))); return f })
            featureCollection.extend(styledFeatures)
        }

        let iconFeatures = []
        countryVector.getSource().on("change", function (ev) {
            if (countryVector.getSource().getState() === "ready") {
                // countryVector.getSource().getFeatures().map(feature => {
                //     let geom = new Point(getPinLocation(feature.getGeometry().getExtent(), feature.get('NAME_EN')))
                //     const iconFeature = new Feature({
                //         name_en: feature.get('NAME_EN'),
                //         name_ar: feature.get('NAME_AR'),
                //         image: feature.get('flag'),
                //         id: feature.get('id'),
                //         typr: "label",
                //         geometry: geom
                //     })
                //     iconFeatures.push(iconFeature)
                // })
                addStyleToFeature(iconFeatures)
            }
        });

        new VectorLayer({
            source: new VectorSource({
                features: featureCollection
            }),
            title: "",
            zIndex: 10000,
            format: new GeoJSON(),
            map: map
        })

        var selectedCountryFeature = null;
        /**
        * Elements that make up the popup.
        */
        var container = document.getElementById('popup');
        var content = document.getElementById('popup-content');
        var closer = document.getElementById('popup-closer');
        var counrty_flag = document.getElementById('popup-image');
        var country_id = document.getElementById('popup-wrapper');
        /**
        * Create an overlay to anchor the popup to the map.
        */
        var overlay = new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        });

        closer.onclick = function () {
            overlay.setPosition(undefined);
            closer.blur();
            return false;
        }

        map.setView(
            new View({
                center: [5270159.8592, 2794537.7541],
                zoom: 5.25
            })
        )

        map.addLayer(countryVector)
        map.addOverlay(overlay)

        map.on('pointermove', function (e) {
            if (selectedCountryFeature !== null) {
                selectedCountryFeature.setStyle(undefined);
                selectedCountryFeature = null;
            }
            map.forEachFeatureAtPixel(e.pixel, function (f) {
                if (f.get('type') === 'country') {
                    selectedCountryFeature = f;
                    f.setStyle(highlightStyle);
                    return true;
                }
            });
        });

        map.on('singleclick', function (evt) {
            var coordinate = evt.coordinate;
            if (selectedCountryFeature) {
                // Workaround If agreementId is null, assign default value = 1
                var agreementID = agreementId !== null ? agreementId : 1
                const countryInfo = agreementCountries.filter( country => selectedCountryFeature.getProperties()['id'] == country.country )[0];
                const region = regions.find(region => region.id === selectedCountryFeature.getProperties()['id'])
                content.innerHTML = `<span>${ selectedCountryFeature.get(intl.locale === 'ar' ? 'NAME_AR' : 'NAME_EN')}</span>
                                     <hr/>
                                     ${intl.formatMessage({ id: "participation_date" })}<span>${countryInfo.join_date}</span>
                                     <hr/>
                                     <span>${intl.formatMessage({ id: "national_focal_points" })}</span><br/>
                                     <div style='text-align: ${intl.locale === 'ar' ? "right" : "left"}'>
                                        ${countryInfo.national_focal_points ? countryInfo.national_focal_points : '' }</div>`;
                counrty_flag.src = selectedCountryFeature.get('flag')
                // country_id.setAttribute("target", "_blank")
                country_id.setAttribute("href", "/wildLifeAgreement/?country=" + region.code)
                overlay.setPosition(coordinate);
            }
        });

        map.setTarget(document.getElementById("wl-map-div"))

    }, [])

    function getPinLocation(Extent, layerName) {
        if (layerName === "State of Qatar") {
            return [5714126.4865, 2820679.2178]
        } else if (layerName === "United Arab Emirates") {
            return [6055035.6326, 2688748.9070]
        } else {
            var X = Extent[0] + (Extent[2] - Extent[0]) / 2;
            var Y = Extent[1] + (Extent[3] - Extent[1]) / 2;
            return [X + 20000, Y - 40000];
        }
    }

    return (
        <>
            <h5 style={{ fontWeight: "bold" }}>
                <FormattedMessage id="participating_countries" />
            </h5>
            <Row className="justify-content-center" style={{ width: "80%", margin: "auto" }}>
                <div id="wl-map-div" />
                <div id="popup" className="olPopup">
                    <img src={CloseLogo} id="popup-closer" alt=""/>
                    <a id="popup-wrapper">
                        <img alt="image" id="popup-image"/>
                        <div id="popup-content" className="popupContent"></div>
                    </a>
                </div>
            </Row>
            <Row className="justify-content-center  mb-3">
               
                    <Row className="mt-3 justify-content-center" style={{ width: "100%", margin: "auto" }}>
                        {regions.map(item => {
                            if (item.code !== "GCC") {
                                return (
                                    <Col key={item.id} >
                                        <div className="justify-content-center single-country">
                                            <a href={"/wildLifeAgreement/?country=" + item.code}>
                                            <Row className="justify-content-center">
                                                <img src={item.image} alt="country flag" className="mb-1"
                                                    style={{
                                                        height: "33px", width: "43px",
                                                        cursor: "pointer",
                                                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                                                    }} />
                                            </Row>
                                            <Row className="justify-content-center"><p className="text-dark">{item.name}</p></Row>
                                            </a>
                                        </div>
                                    </Col>)
                            } else {
                                return ''
                            }
                        })}
                    </Row>
               
            </Row>
        </>
    )
}

const mapStateToProps = state => {
    return {
        regions: state.regions.regions
    }
}


export default connect(mapStateToProps)(injectIntl(ParticipatingCountriesSection))
