import React from "react"
import { Col, Row ,Spinner} from "reactstrap"
import { injectIntl } from "gatsby-plugin-intl"


class Content extends React.Component  {
    constructor(props) {
        super(props);
    }
    render() {
    const isArabicLocale = this.props.intl.locale === "ar";
    return (
        <Row> 

        { this.props.isLoading &&  this.props.contents ? <div style={{width:"100%"}} ><Spinner /></div>  :
            this.props.contents.map((info, i) =>

                <Col sm="3" key={i}>
                    <div className="sub-title">
                        <span className="box"  style={{ backgroundColor: this.props.handleColor(info.category)}}  className={isArabicLocale ? 'box ml-2': 'box mr-2'}></span>
                        <span className="date">{info.date}</span>
                    </div>
                    <div className="description-txt"  align={isArabicLocale ? "right" : "left"}>
                        {info.description}

                    </div>

                </Col>)
        }
    </Row>
    )
    }
}

export default injectIntl(Content)