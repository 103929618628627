import React, { Component } from "react"
import { connect } from "react-redux"
import { Container, Row } from "reactstrap"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../containers/Layout"
import SEO from "../components/seo"
import axios from "../api/axios"
import SummarySection from "../components/agreementDetails/summarySection";
import BasicDetailsSection from "../components/agreementDetails/basicDetailsSection"
import GallerySection from "../components/gallerySection"
import RelatedDocumentsSection from "../components/agreementDetails/relatedDocumentsSection"
import RelatedLinksSection from "../components/agreementDetails/relatedLinksSection"
import ParticipatingCountriesSection from "../components/agreementDetails/participatingCountriesSection"
import InfoTabs from "../components/agreementDetails/informationTabs"
import { FormattedMessage } from "react-intl"
import Axios from "axios"
import AgreementCountryDetails from "./agreementCountryDetails";
import RelatedLinksImg from '../images/wildlife/12.jpg'


class AgreementDetailsPage extends Component {

    state = {
        agreement: null,
        images: [],
        agreementsCountries: null,
        agreementsCategories: [],
        isLoading: true
    }

    componentDidMount() {
        const requestAgreements = axios(this.props.intl.locale, `agreements/`)
        const requestImages = axios(this.props.intl.locale, `agreements_images/`)
        const requestAgreementsCountries = axios(this.props.intl.locale, `agreements_countries/`)
        const requestAgreementsCategories = axios(this.props.intl.locale, `agreements_content_categories/`)


        Axios.all([requestAgreements, requestImages, requestAgreementsCountries,requestAgreementsCategories])
            .then(Axios.spread((...responses) => {
                const agreements = responses[0]
                const images = responses[1]
                this.setState({
                    agreement: agreements.data[0],
                    agreementsCountries: responses[2].data,
                    agreementsCategories:responses[3].data,
                    isLoading: false
                })
                const urlImages = []
                images.data && images.data.map(image => {
                    return urlImages.push(image.image)
                })
                this.setState({ images: urlImages })
            }))
    }

    componentDidUpdate(prevProps){
    }

    render() {
        return (
            <Layout>
                <SEO title="Agreements" />
                { this.props.selectedRegionId == 7 ? // GCC region
                <div className="main ">
                    <div className="section text-center wildlife-agreement">
                        <div className="mt-5 mb-4 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }} >
                            <Container >
                                <Row>
                                    <h3 className="mt-1"><FormattedMessage id="wildLifeKeepingAgreement" /></h3>
                                </Row>
                            </Container>
                        </div>

                        <Container>
                            <BasicDetailsSection agreement={this.state.agreement} />
                        </Container>
                     
                           
                            <div className="information-tabs">
                            <Container>
                                <InfoTabs agreementsCategories={this.state.agreementsCategories} isLoading={this.state.isLoading} />
                             </Container>
                            </div>
                            
                     
                          
                        <Container>    
                            {!this.state.isLoading && <ParticipatingCountriesSection agreementId={this.state.agreement && this.state.agreement.id}
                                                                                 agreementCountries={this.state.agreementsCountries} />}

                        </Container>
                       
                             <section className="quality related-links">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg">
                                            <div className="quality-txt-holder">
                                                <h2><FormattedMessage id="related_links" /></h2>
                                              
                                                <RelatedLinksSection links={this.state.agreement && this.state.agreement.related_links} />
                                              
                                                <h2><FormattedMessage id="related_documents" /></h2>
                                               
                                                <RelatedDocumentsSection docs={this.state.agreement && this.state.agreement.related_documents} /> 
                                                
                                             
                                            </div>
                                        </div>
                                        <div className="col-lg pr-0 pl-0 ">
                                            <img className="w-100 related-img" src={RelatedLinksImg} alt="..." /> 
                                        </div>
                                    </div>
                                </div>
                            </section> 
                        <Container>
                            <GallerySection titleMsgId="agreement_gallery" images={this.state.images} />
                        </Container>
                    </div>
                </div> : (this.state.agreement && <AgreementCountryDetails {...this.props} agreementId={this.state.agreement.id}/>)
                    }
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
      selectedRegionId: state.regions.selectedRegion && state.regions.selectedRegion.id
    }
  }

export default (connect(mapStateToProps)(injectIntl(AgreementDetailsPage)))