import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import Content from "../agreementDetails/content"
import axios from "../../api/axios"
import classnames from 'classnames';


class InfoTabs extends React.Component {

    state = {
        contents: [],
        activeTab: '0',
        isLoading: true
    }
    componentDidMount() {
        this.getALLContents()
        
    }
    getALLContents = () => {
        axios(this.props.intl.locale, `agreements_content/`)
            .then(response => {
                const allContents = [...response.data]
                this.setState({ contents: allContents, isLoading: false });
            })
    }
    getOneContent = (id) => {
        axios(this.props.intl.locale, `agreements_content/?category=${id}`)
            .then(response => {
                const agreementContents = [...response.data]
                this.setState({ contents: agreementContents, isLoading: false });
            })
    }

    toggle = (tab) => {
        if (tab === '0') {
            this.getALLContents()
        } else {
            this.getOneContent(tab)
        }
        this.setState({
            activeTab: tab
        })
    }
    getColor = (categoryId) => {
        if (!this.props.isLoading) {
            const category = this.props.agreementsCategories.filter(content => content.id == categoryId)
            const color = category[0].color;
            return color;   
        }
    }
 
    render() {
        const { activeTab, contents, isLoading } = this.state
        
        return (

            <>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '0' })}
                            onClick={(e) => { this.toggle('0'); }}
                        >
                            <FormattedMessage id="all" />
                        </NavLink>
                    </NavItem>
                    {
                        this.props.agreementsCategories && this.props.agreementsCategories.map((tab, i) =>
                            <NavItem key={tab.id}>
                                <NavLink
                                    className={classnames({ active: activeTab === tab.id })}
                                    onClick={(e) => { this.toggle(tab.id) }}
                                >
                                    {tab.title}

                                </NavLink>
                                <span className="line" style={{ backgroundColor: `${tab.color}` }}></span>
                            </NavItem>
                        )
                    }
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId='0' >
                       <Content contents={contents} isLoading={isLoading}  handleColor={this.getColor}/> 
                    </TabPane>

                    {
                        this.props.agreementsCategories && this.props.agreementsCategories.map(tab =>
                            <TabPane tabId={tab.id} key={tab.id} >
                                 <Content contents={contents} isLoading={isLoading}  handleColor={this.getColor}/> 
                            </TabPane>
                        )
                    }

                </TabContent>
            </>
        );
    }
}


export default injectIntl(InfoTabs)




