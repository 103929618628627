import React from "react"
import { Container, Row } from "reactstrap"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"



const SummarySection = ({ intl, summary }) => {
    const isArabicLocale = intl.locale === "ar";
    const textStyle = { textAlign: isArabicLocale ? "right" : "left" }

    return (
        <Row className="justify-content-center  mb-3">
            <h5 style={{ fontWeight: "bold" }}>
                <FormattedMessage id="agreement_summary" />
            </h5>
            <Container>
                <p style={textStyle}
                    dangerouslySetInnerHTML={{ __html: summary || <FormattedMessage id="no_data_found" /> }} />
            </Container>
        </Row>
    )
}



export default injectIntl(SummarySection)
