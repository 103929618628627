import React, {useEffect} from "react"
import { Col, Row } from "reactstrap"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"


const BasicDetailsSection = ({ intl, agreement}) => {
    const isArabicLocale = intl.locale === "ar";
    const headingsStyle = { textAlign: isArabicLocale ? "right" : "left", fontWeight: "600" }
    return (
        <Row className="mb-5">
            <Col sm={12}>
                <Row>
                    <Col md={3} >
                        <p style={headingsStyle}><FormattedMessage id="agreement_title" /></p>
                    </Col>
                    <Col md={9} >
                        <p style={{ textAlign: isArabicLocale ? "right" : "left" }}>
                            {agreement ? agreement.name : <FormattedMessage id="no_data_found" />}
                        </p>
                    </Col>

                    <Col md={3} >
                        <p style={headingsStyle}><FormattedMessage id="agreement_date" /></p>
                    </Col>
                    <Col md={9} >
                        <p style={{ textAlign: isArabicLocale ? "right" : "left" }}>
                            {agreement ? agreement.date : <FormattedMessage id="no_data_found" />}
                        </p>
                    </Col>

                    <Col md={3} >
                        <p style={headingsStyle}><FormattedMessage id="agreement_purpose" /></p>
                    </Col>
                    <Col md={9} >
                        <p style={{ textAlign: isArabicLocale ? "right" : "left" }}
                            dangerouslySetInnerHTML={{ __html: agreement ? agreement.description : <FormattedMessage id="no_data_found" /> }} />
                    </Col>
                </Row>
            </Col>
        </Row >
    )
}



export default injectIntl(BasicDetailsSection)
