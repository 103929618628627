import React from "react"
import { Col, Row } from "reactstrap"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"


const RelatedLinksSection = ({ intl, links }) => {
    const isArabicLocale = intl.locale === "ar";

    return (
    
              
                <Row >
                    {links && links.map((link, i) =>
                        <Col key={i} md={12} align={isArabicLocale ? "right" : "left"}>
                            <a href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a>
                        </Col>)}
                   
                </Row>
          
    )
}

export default injectIntl(RelatedLinksSection)
