import React from "react"
import { Row, Col } from "reactstrap"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import FileIcon from "../../images/file_icon.png"



const RelatedDocumentsSection = ({ intl,docs }) => {

    const isArabicLocale = intl.locale === "ar";
    return (
        
      <>
            {docs &&
             
                  
                    <Row >
                        {docs && docs.map((doc,i) =>
                            <Col key={i} md={12} align={isArabicLocale ? "right" : "left"}>
                              <a href={doc.document} target="_blank" rel="noopener noreferrer"><img src={FileIcon} style={{ width: "25px", height: "30px" }} alt="img" />{doc.name}</a>
                            </Col>
                        )}
                      
                    </Row>
               
            }
        </>
    )
}


export default injectIntl(RelatedDocumentsSection)
